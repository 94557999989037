.App {
  background-color: #000;
}

.App-header {
  color: #fff;
  min-height: 100vh;
}

.img-container {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  background-color: azure;
}

.img-container img {
  object-fit: contain;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15vh 0;
}

.heading {
  margin: 0.4em 0 0.2em 0;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  outline: none;
  border: none;
  margin: 0.5em 0;
  cursor: pointer;
}

.btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-accept {
  background-color: #1ECD47;
}

.btn-reject {
  background-color: #F33F45;
}

.btn-container {
  position: absolute;
  bottom: 6em;
  display: flex;
  justify-content: center;
  gap: 9em;
}

.footer {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .main {
    padding: 20vh 0;
  }
}

@media (min-width: 1200px) {
  .main {
    padding: 25vh 0;
  }
}